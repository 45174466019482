<div class="modal-header">
  <h1 class="modal-title">Contact Us</h1>
  <button (click)="closeModal()" aria-label="Close" class="btn-close" type="button"></button>
</div>

<div class="modal-body">
  @if (message()) {
    <div class="alert alert-dismissible" [ngClass]="{ 'alert-success': isSuccessMsg(), 'alert-danger': !isSuccessMsg() }" role="alert">
      <button type="button" class="btn-close" aria-label="Close" (click)="message.set(undefined)"></button>
      <div class="alert-message">{{ message() }}</div>
    </div>
  }

  <form style="min-height: 500px" [formGroup]="form()">
    <formly-form [hidden]="isCaptchaVisible()" [form]="form()" [fields]="formFields()" />
    @if (isCaptchaVisible()) {
      <app-captcha #captcha (isValidChange)="onCaptchaComplete($event)" />
      @if (captcha.isLoading()) {
        <div class="d-flex p-2" style="height: 100%; align-items: center; justify-content: center">
          <span class="spinner-border" style="--bs5-spinner-width: 4rem; --bs5-spinner-height: 4rem"></span>
        </div>
      }
    } @else {
      <p class="text-center mt-3 mb-2" data-cy="supportMessage">
        For pharmacy technical support, please text your NPI or call 833.613.2333
      </p>
      <div class="text-center">
        <chitin-button label="Submit" (clicked)="submit()" [disabled]="form().invalid" [isLoading]="isSaving()" data-cy="submitBtn" />
      </div>
    }
  </form>
</div>
