import { FormlyFieldConfig } from '@ngx-formly/core';
import { cloneDeep } from 'lodash';

export class ContactUsForm {
  static create() {
    const contactUsFormFields: FormlyFieldConfig[] = [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12',
            key: 'first_name',
            type: 'input',
            props: {
              label: 'First Name',
              cols: 12,
              required: true,
              maxLength: 255,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12',
            key: 'company_name',
            type: 'input',
            props: {
              cols: 12,
              label: 'Company Name',
              maxLength: 255,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12',
            key: 'email',
            type: 'input',
            validators: {
              validation: ['email'],
            },
            props: {
              cols: 12,
              label: 'Email',
              required: true,
              maxLength: 255,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12',
            key: 'phone_number',
            type: 'phone',
            validators: {
              validation: ['phone'],
            },
            props: {
              cols: 12,
              label: 'Phone Number',
              required: true,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12',
            key: 'description',
            type: 'textarea',
            props: {
              label: 'What can we help you with?',
              required: true,
              cols: 12,
              rows: 10,
              autosize: false,
            },
          },
        ],
      },
    ];
    return cloneDeep(contactUsFormFields);
  }
}
