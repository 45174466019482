import { Component, inject, signal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ContactUsForm } from '@app/root/components/contact-us-modal/contact-us-form';
import { ToastService } from '@app/root/providers/toast-service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SupportRequestsService } from '@services/support-requests.service';
import { ISupportRequest } from '@shared/models/support-request.model';
import moment from 'moment/moment';

@Component({
  selector: 'app-contact-us-modal',
  templateUrl: './contact-us-modal.component.html',
})
export class ContactUsModalComponent {
  form = signal<FormGroup>(new FormGroup({}));
  formFields = signal<FormlyFieldConfig[]>(ContactUsForm.create());
  isSaving = signal(false);
  isCaptchaVisible = signal(false);
  captchaValid = signal(false);
  isSuccessMsg = signal(false);
  message = signal<string | undefined>(undefined);

  protected toastService = inject(ToastService);
  protected ngbActiveModal = inject(NgbActiveModal);
  protected supportRequestsService = inject(SupportRequestsService);

  closeModal() {
    this.ngbActiveModal.close();
  }

  submit() {
    if (!this.captchaValid()) {
      this.isCaptchaVisible.set(true);
      return;
    }

    if (this.form().invalid) return;
    this.isSaving.set(true);
    const requestData: Partial<ISupportRequest> = {
      requester_id: 'login_page',
      help_topic: 'brightscrip.com Contact Form Inquiry',
      requested_time: moment().toISOString(),
      tenant: '',
      ...this.form().value,
    };

    this.supportRequestsService.submitRequest(requestData, false).subscribe({
      next: () => {
        this.isSaving.set(false);
        this.isSuccessMsg.set(true);
        this.toastService.show('Your request has been successfully sent');
        this.closeModal();
      },
      error: () => {
        this.isSaving.set(false);
        this.isSuccessMsg.set(false);
        this.message.set("Your request hasn't been successfully sent. Please try again later.");
      },
    });
  }

  protected onCaptchaComplete(valid: boolean) {
    if (valid) {
      this.captchaValid.set(true);
      this.isCaptchaVisible.set(false);
      this.submit();
    } else {
      this.captchaValid.set(false);
    }
  }
}
